import React from "react";
import { Box } from "../../styled/Elements";
import { Controller } from "react-hook-form";
import ValidationErrorText from "./ValidationErrorText";

const ValidationController = ({
  control,
  name,
  validationConfig,
  renderer,
  errors,
  form,
}) => {
  return (
    <Box>
      <Controller
        name={name}
        control={control}
        rules={validationConfig}
        render={renderer}
      />
      {errors[name] && !form[name]?.length && !errors[name]?.ref?.value && (
        <ValidationErrorText text={errors[name]?.message} />
      )}
    </Box>
  );
};

export default ValidationController;

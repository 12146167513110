import React, { useEffect, useState } from "react";
import { Box } from "../../styled/Elements";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css/pagination";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const AuthSlider = ({ slides }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const [sliderKey, setSliderKey] = useState(0);

  useEffect(() => {
    setSliderKey((prevKey) => prevKey + 1);
  }, [language]);

  return (
    <Box>
      <Swiper
        key={sliderKey}
        pagination={true}
        modules={[Pagination, Autoplay]}
        loop={true}
        className="mySwiper"
        dir={language === "ar" ? "rtl" : "ltr"}
        autoplay={{
          pauseOnMouseEnter: true,
        }}
      >
        {slides &&
          slides.length > 0 &&
          slides.map((slide, key) => (
            <SwiperSlide key={key}>
              <Box className="d-flex justify-content-center">{slide}</Box>
            </SwiperSlide>
          ))}
      </Swiper>
    </Box>
  );
};

export default AuthSlider;

export const SliderWrapper = styled.div``

import React, { useState } from "react";
import { Box } from "../../../components/styled/Elements";
import ListTable from "../../../components/common/tables/ListTable";
import { receivedReqColumns } from "../../../helpers/adminConstants/TableConstants";
import { receivedReqsRows } from "../../../helpers/dummyConstants";
import ReasonModal from "../../../components/admin/Modals/ReasonModal/ReasonModal";

const ReceivedRequests = () => {
    const [showModal, setShowModal] = useState(false)
    const [reason, setReason] = useState('')
    const handleReason = () => {
        setShowModal(true)
    }
    const handleReasonChange = (e) => {
        const {value} = e.target;
        setReason(value)
    }
  return (
    <Box>
      <ListTable
        // options={["open", "info", "edit", "transfer", "status", "delete"]}
        columns={receivedReqColumns}
        rows={receivedReqsRows}
        onReject={handleReason}
        // totalCount={totalCount}
        // perPage={perPage}
        // activePage={activePage}
        // setActivePage={setActivePage}
        // onEdit={handleEdit}
        // onDelete={handleDelete}
        // onView={handleView}
        // onInfoView={handleInfo}
        // onStatusToggle={handleStatusToggle}
        // onTransfer={handleTransfer}
      />
      <ReasonModal show={showModal} onHide={() => setShowModal(false)} onChange={handleReasonChange}/>
    </Box>
  );
};

export default ReceivedRequests;

import React from "react";
import {
  Box,
  ButtonStyled,
  FormButtonStyled,
  LabelStyled,
  SpanStyled,
  TextStyled,
} from "../../../../components/styled/Elements";
import CustomRadioButton from "../../../../components/common/radioButtons/CustomRadio";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import ValidatedInput from "../../../../components/common/inputFields/ValidatedInput";
import { Form } from "react-bootstrap";
import { FileDown } from "../../../../components/styled/AllImages";

const SignupStep2 = ({ handleSteps, paymentMethod, setPaymentMethod }) => {
  const { t } = useTranslation();
  const { register } = useForm();

  const handleChange = (e) => {
    const { value } = e.target;

    setPaymentMethod(parseInt(value));
  };

  return (
    <Step2Wrapper>
      <Form>
        <Box>
          <Box>
            <ValidatedInput
              name="num_of_licenses"
              label={"num_of_licenses"}
              type="input"
              fieldType="text"
              placeholder={"numOfLicensePlaceholder"}
              className={"mb-3"}
              bg="var(--white)"
              register={register}
            />
          </Box>
          <Box className="d-flex justify-content-between align-items-center my-4">
            <Box>
              <TextStyled
                size="18px"
                color="var(--themeColor)"
                className="align-items-sm-baseline"
                weight="700"
              >
                {t("total")}: 640 {t("sar")}
                <SpanStyled size="14px">
                  &nbsp; &nbsp;
                  {t("inc_tax")}
                </SpanStyled>
              </TextStyled>
            </Box>
            <Box>
              <ButtonStyled
                label={t("download_invoice")}
                border="var(--themeColor)"
                color="var(--themeColor)"
                bg="var(--white)"
                shadow="none"
                size="14px"
                icon={FileDown}
              />
            </Box>
          </Box>
          <Box className="mb-3">
            <Box>
              <LabelStyled
                size="16px"
                weight="bold"
                color="var(--text)"
                className="mb-1"
              >
                {t("choose_payment_method")}
              </LabelStyled>
            </Box>
            <Box className="d-flex gap-3">
              <CustomRadioButton
                name="type"
                label={t("card")}
                value={1}
                checked={paymentMethod === 1}
                onChange={handleChange}
              />

              <CustomRadioButton
                name="type"
                label={t("bank_transfer")}
                value={2}
                checked={paymentMethod === 2}
                onChange={handleChange}
              />
            </Box>
          </Box>
        </Box>
        <FormButtonStyled
          label={t("next")}
          color="var(--white)"
          bg="var(--themeColor)"
          className="w-100"
          clName="w-100 my-4"
          disabled={!paymentMethod}
          onClick={handleSteps}
        />
      </Form>
    </Step2Wrapper>
  );
};

export default SignupStep2;

const Step2Wrapper = styled.div``;

import React, { useState } from "react";
import {
  Box,
  FormButtonStyled,
  HeadingStyled,
  TextStyled,
} from "../../../components/styled/Elements";
import AuthHeader from "../../../components/auth/AuthHeader/AuthHeader";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { LoginWrapper } from "./styled";
import AuthSlider from "../../../components/auth/authSlider/AuthSlider";
import { AuthSlides } from "../../../helpers/authConstants/AuthConstants";
import ValidatedInput from "../../../components/common/inputFields/ValidatedInput";
import ForgetPasswordModal from "./components/ForgetPasswordModal";
import EmailSentModal from "./components/EmailSentModal";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const { t } = useTranslation();
  const { register } = useForm();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [emailSentModalShow, setEmailSentModalShow] = useState(false);

  const handleBtnClick = () => {
    setShowModal(false);
    setEmailSentModalShow(true);
  };

  return (
    <LoginWrapper>
      <AuthHeader />
      <Box className="mt-4">
        <Row>
          <Col lg={6} className="px-4 px-md-5 overflow-y-auto">
            <Box className="mb-4">
              <HeadingStyled size="24px" weight="bold" color="var(--text)">
                {t("wellcome_back")}
              </HeadingStyled>
              <TextStyled size="16px" color="var(--text2)">
                {t("please_enter_email_password")}
              </TextStyled>
            </Box>
            <Box>
              <Box>
                <ValidatedInput
                  name="email"
                  label={"email"}
                  type="input"
                  fieldType="text"
                  placeholder={"emailPlaceholder"}
                  register={register}
                  className={"mb-3"}
                  bg="var(--white)"
                />
                <ValidatedInput
                  name="password"
                  label={"password"}
                  type="input"
                  fieldType="password"
                  placeholder={"passwordPlaceholder"}
                  register={register}
                  className={"mb-3"}
                  bg="var(--white)"
                />
                <FormButtonStyled
                  label={t("login")}
                  color="var(--white)"
                  bg="var(--themeColor)"
                  className="w-100"
                  clName="w-100 my-4"
                  onClick={() => navigate("/dashboard")}
                />
              </Box>
              <Box className="d-flex justify-content-end">
                <Box>
                  <TextStyled
                    className="cursor-pointer"
                    color="var(--themeColor)"
                    size="16px"
                    weight="500"
                    onClick={() => setShowModal(true)}
                  >
                    {t("forgot_password")}
                  </TextStyled>
                </Box>
              </Box>
              <Box>
                <Box>
                  <TextStyled
                    className="justify-content-center align-items-center or-separator"
                    color="var(--text)"
                    size="16px"
                  >
                    {t("or")}
                  </TextStyled>
                </Box>
              </Box>
              <FormButtonStyled
                label={t("login_with_phone_num")}
                color="var(--white)"
                bg="var(--themeColor2)"
                className="w-100"
                clName="w-100 my-4"
                onClick={() => navigate("/login-with-phone")}
              />
            </Box>
          </Col>
          <Col lg={6}>
            <Box className="sticky-item">
              <AuthSlider slides={AuthSlides} />
            </Box>
          </Col>
        </Row>
      </Box>
      <ForgetPasswordModal
        show={showModal}
        onHide={() => setShowModal(false)}
        onBtnClick={handleBtnClick}
      />
      <EmailSentModal
        show={emailSentModalShow}
        onHide={() => setEmailSentModalShow(false)}
      />
    </LoginWrapper>
  );
};

export default Login;

import AddCabinet from "../pages/admin/cabinets/AddCabinet";
import Cabinets from "../pages/admin/cabinets/Cabinets";
import AddFile from "../pages/admin/cabinets/files/addFile/addFile";
import Files from "../pages/admin/cabinets/files/Files";
import Dashboard from "../pages/admin/dashboard/Dashboard";

import Profile from "../pages/admin/profile/Profile";
import Requests from "../pages/admin/requests/Requests";


export const privateRoutes = [
    {
        path: "dashboard",
        element: <Dashboard/>,
    },
    {
        path: "cabinets",
        element: <Cabinets/>,
    },
    {
        path: "cabinets/add-cabinet",
        element: <AddCabinet/>,
    },
    {
        path: "cabinets/files",
        element: <Files/>,
    },
    {
        path: "profile",
        element: <Profile/>,
    },
    {
        path: "cabinets/files/add-file",
        element: <AddFile />,
    },
    {
        path: "requests",
        element: <Requests />,
    },
    
]
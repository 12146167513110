import React from "react";
import { Box, SubHeadingStyled } from "../../../components/styled/Elements";
import { dashboardTileArray } from "../../../helpers/adminConstants/AdminConstants";
import CustomTile from "../../../components/common/statusTiles/CustomTile";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DashboardWrapper } from "./styled";
import PendingReceivedReqs from "./components/PendingReceivedReqs";
import PendingSentReqs from "./components/PendingSentReqs";

const Dashboard = () => {
	const { t } = useTranslation();
	return (
		<DashboardWrapper>
			<Box className="content-head py-3 px-3 px-md-4">
				<SubHeadingStyled size="16px" weight="700" color="var(--text)">
					{t("dashboard")}
				</SubHeadingStyled>
			</Box>
			<Box className="content-container">
				<Row>
					{dashboardTileArray.map((tile, key) => (
						<Col lg={3} md={6} sm={12} className="my-2">
							<CustomTile key={key} {...tile} />
						</Col>
					))}
				</Row>
				<Row className="mt-3">
					<Col lg={6} md={12}>
						<PendingReceivedReqs />
					</Col>
					<Col lg={6} md={12}>
						<PendingSentReqs />
					</Col>
				</Row>
			</Box>
		</DashboardWrapper>
	);
};

export default Dashboard;
